import {
  fetchSignInMethodsForEmail,
  linkWithPopup,
  signInWithEmailAndPassword,
  signInWithPopup,
} from "firebase/auth";

import { authProvider } from "../firebase/firebase";
import { fetchMyUser } from "../lib/api";
import { auth } from "@/firebase/firebase";

export const signInWithEmail = async (email: string, password: string) => {
  return signInWithEmailAndPassword(auth, email, password);
};

export const signInWithGoogle = () => {
  const provider = authProvider.google;
  provider.setCustomParameters({ prompt: "select_account" });

  return signInWithPopup(auth, provider);
};

export const signInWithMicrosoft = () => {
  const provider = authProvider.microsoft;
  provider.setCustomParameters({
    promp: "select_account",
    tenant: "common",
  });

  return signInWithPopup(auth, provider);
};

type SignInMethod = "email" | "google.com" | "microsoft.com";
export type AuthProvider = "google" | "email" | "microsoft";

const getProviderForMethod = (method: SignInMethod): AuthProvider => {
  switch (method) {
    case "google.com":
      return "google";
    case "microsoft.com":
      return "microsoft";
    default:
      return "email";
  }
};

export const linkAccounts = async (
  providerId: AuthProvider,
  email: string,
  setErrorMessage: (message: string) => void
) => {
  try {
    const signInMethods = await fetchSignInMethodsForEmail(auth, email);

    if (signInMethods.length > 0) {
      const methodId = getProviderForMethod(signInMethods[0] as SignInMethod);

      if (!methodId) {
        throw new Error("Unsupported sign-in method");
      }

      const providerToLink = authProvider[methodId];
      const result = await signInWithPopup(auth, providerToLink);
      await linkWithPopup(result.user, authProvider[providerId]);
      await fetchMyUser();
    }
  } catch (err: any) {
    console.error("Error linking accounts: ", err);
    setErrorMessage("An error occurred. Please try again.");
  }
};
