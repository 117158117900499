import { useContext, useEffect, useState } from "react";
import { useMediaQuery } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { UserCredential } from "firebase/auth";
import Head from "next/head";
import { useRouter } from "next/router";
import {
  FooterContainer,
  HeaderContainer,
  IconButtonContainer,
  LHSContainer,
  LoginContainer,
  LoginPage,
  RHSContainer,
} from "styles/LogIn";

import { theme } from "@bxtech/bx-ui-lib";
import IconButton from "@bxtech/bx-ui-lib/elements/IconButton";
import Logo from "@bxtech/bx-ui-lib/elements/Logo";
import Footer from "@bxtech/bx-ui-lib/sections/navigation/Footer";
import { ArrowBackIcon } from "@bxtech/bx-ui-lib/styles/primitives/icons/MuiIcons";

import { LoadingContainer } from "../sections/LogIn/styles";
import {
  AuthProvider,
  linkAccounts,
  signInWithEmail,
  signInWithGoogle,
  // signInWithMicrosoft,
} from "../services/authService";
import { RoleType } from "../types/api";
import { UserContext } from "@/context/UserContext";
import navigationFooter from "@/data/navigationFooter";
import { extractRoleInfo, fetchMyUser } from "@/lib/api";
import LogIn from "@/sections/LogIn";

const LOGO_URL = `${process.env.NEXT_PUBLIC_BX_CDN_URL}/bx.svg`;

const indexPage = () => {
  const { user } = useContext(UserContext);
  const [errorMessage, setErrorMessage] = useState("");
  const router = useRouter();
  const [stage, setStage] = useState<"email" | "password">("email");
  const isLargeScreen = useMediaQuery(theme.breakpoints.down("xl"));

  // Navigation based on user role
  const navigateBasedOnRole = (
    roleType: RoleType | null,
    enterpriseId: number | null,
    organisationId: number | null
  ) => {
    switch (roleType) {
      case "SUPER_ADMIN":
        router.push("/superAdminDashboard");
        break;
      case "ENTERPRISE_ADMIN":
        if (!enterpriseId) {
          router.push("/multiEnterpriseDashboard");
          break;
        }
        router.push(`/enterpriseDashboard/${enterpriseId}`);
        break;
      case "ORGANISATION_ADMIN":
        router.push(
          `/enterpriseDashboard/${enterpriseId}/dashboard/${organisationId}`
        );
        break;
      default:
        setErrorMessage("User does not have a supported role.");
    }
  };

  useEffect(() => {
    if (user) {
      const { roleType, enterpriseId, organisationId } = extractRoleInfo(user);
      navigateBasedOnRole(roleType, enterpriseId, organisationId);
    }
  }, [user, router]);

  const handleSSOSignIn = async (
    signInMethod: {
      (): Promise<UserCredential>;
    },
    providerId: string
  ) => {
    try {
      await signInMethod();
      await fetchMyUser();
    } catch (err: any) {
      if (err.code === "auth/account-exists-with-different-credential") {
        linkAccounts(
          providerId as AuthProvider,
          err.customData.email,
          setErrorMessage
        );
      } else {
        setErrorMessage("This email is not associated with a BX account.");
      }
    }
  };

  if (user) {
    return (
      <LoadingContainer>
        <CircularProgress />
      </LoadingContainer>
    );
  }

  const submit = async ({
    email,
    password,
  }: {
    email: string;
    password: string;
  }) => {
    try {
      await signInWithEmail(email, password);
      await fetchMyUser();
    } catch (err: any) {
      if (err.message === "Firebase: Error (auth/user-not-found).") {
        setErrorMessage("Your email or password is incorrect.");
      } else {
        setErrorMessage(err.message);
      }
    }
  };

  const forgottenPasswordClickHandler = () => {
    router.push("/forgottenPassword");
  };

  return (
    <>
      <Head>
        <link
          rel="preload"
          href={`${process.env.NEXT_PUBLIC_BX_CDN_URL}/authBackground.jpg`}
          as="image"
        />
      </Head>
      <LoginPage>
        <HeaderContainer stage={stage}>
          {stage === "password" && (
            <IconButtonContainer stage={stage}>
              <IconButton
                style={{
                  height: "100%",
                }}
                variant="minimal"
                onClick={() => setStage("email")}
                icon={<ArrowBackIcon />}
                intent="secondary"
              />
            </IconButtonContainer>
          )}
          <Logo logoSrc={LOGO_URL} height={isLargeScreen ? 32 : 28} />
        </HeaderContainer>

        <LHSContainer>
          <LoginContainer>
            <LogIn
              stage={stage}
              setStage={setStage}
              handleGoogleSignIn={() =>
                handleSSOSignIn(() => signInWithGoogle(), "google")
              }
              // handleMicrosoftSignIn={() =>
              //   handleSSOSignIn(() => signInWithMicrosoft(), "microsoft")
              // }
              onSubmit={submit}
              errorMessage={errorMessage}
              setErrorMessage={setErrorMessage}
              forgottenPasswordOnClick={forgottenPasswordClickHandler}
            />
          </LoginContainer>
        </LHSContainer>
        <RHSContainer />
      </LoginPage>
      <FooterContainer>
        <Footer navigation={navigationFooter} />
      </FooterContainer>
    </>
  );
};

export default indexPage;
