import React, { useEffect, useRef } from "react";
import { Typography, useMediaQuery } from "@mui/material";
import { FormikProvider, useFormik } from "formik";
import * as Yup from "yup";

import { ContentHeader, theme } from "@bxtech/bx-ui-lib";
import Button from "@bxtech/bx-ui-lib/elements/Button";
import Divider from "@bxtech/bx-ui-lib/elements/Divider";
import Link from "@bxtech/bx-ui-lib/elements/Link";
import TextFieldOld from "@bxtech/bx-ui-lib/elements/TextFieldOld";
import GoogleIcon from "@bxtech/bx-ui-lib/styles/primitives/icons/GoogleIcon";
// import MicrosoftIcon from "@bxtech/bx-ui-lib/styles/primitives/icons/MicrosoftIcon";
import { ChevronRightIcon } from "@bxtech/bx-ui-lib/styles/primitives/icons/MuiIcons";

import {
  DividerContainer,
  ErrorContainer,
  FormContainer,
  GetInTouchContainer,
  HeaderContainer,
  LogInAndBackButton,
  NextContainer,
  SSOButtons,
} from "./styles";

type Props = {
  stage: "email" | "password";
  setStage: (stage: "email" | "password") => void;
  errorMessage?: string;
  setErrorMessage: (errorMessage: string) => void;
  // handleMicrosoftSignIn: () => void;
  handleGoogleSignIn: () => void;
  onSubmit: (values: LoginFormValues) => void;
  forgottenPasswordOnClick?: () => void;
  style?: React.CSSProperties;
};

type LoginFormValues = {
  email: string;
  password: string;
};

const LogIn = ({
  stage,
  setStage,
  errorMessage,
  setErrorMessage,
  // handleMicrosoftSignIn,
  handleGoogleSignIn,
  onSubmit,
  forgottenPasswordOnClick,
  style,
}: Props) => {
  const passwordInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (stage === "password" && passwordInputRef.current) {
      passwordInputRef.current?.focus();
    }
  }, [stage]);

  const validationSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email address").required("Required"),
    password: Yup.string().required("Required"),
  });

  const initialValues: LoginFormValues = { email: "", password: "" };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values, { setSubmitting }) => {
      onSubmit(values);
      setSubmitting(false);
    },
  });

  const handleNextClick = () => {
    if (stage === "email") {
      // Validate the email field and move to the password stage if there are no errors
      formik.validateField("email").then(() => {
        if (!formik.errors.email) {
          setStage("password");
        }
      });
    } else {
      // Submit the form if on the password stage
      formik.submitForm();
    }
  };

  const isMediumScreen = useMediaQuery(theme.breakpoints.down("xl"));

  return (
    <FormikProvider value={formik}>
      <FormContainer style={style}>
        <HeaderContainer>
          <ContentHeader
            heading={stage === "email" ? "Log in" : "Enter password"}
            size="medium"
          />
        </HeaderContainer>
        {stage === "email" && (
          <>
            <>
              <SSOButtons>
                {/* <Button
                  label="Continue with Microsoft"
                  color="secondary"
                  variant="contained"
                  sx={{ height: "44px" }}
                  icon={<MicrosoftIcon />}
                  onClick={() => {
                    handleMicrosoftSignIn();
                  }}
                /> */}
                <Button
                  label="Continue with Google"
                  color="secondary"
                  variant="contained"
                  sx={{ height: "44px" }}
                  icon={<GoogleIcon />}
                  onClick={() => {
                    handleGoogleSignIn();
                  }}
                />
                {errorMessage ===
                  "This email is not associated with a BX account." && (
                  <ErrorContainer>
                    <Typography variant="annotation" color="error">
                      {errorMessage}
                    </Typography>
                  </ErrorContainer>
                )}
              </SSOButtons>

              <DividerContainer>
                <Divider style={{ height: "1px", width: "25%" }} />
                <Typography
                  variant="subtitle2"
                  style={{ minWidth: "fit-content" }}
                >
                  Or log in via email
                </Typography>
                <Divider style={{ height: "1px", width: "25%" }} />
              </DividerContainer>
            </>

            <TextFieldOld
              label="Email address"
              required
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
              value={formik.values.email}
              onChange={(e) => {
                formik.handleChange(e);
                if (errorMessage) {
                  // Set the error message to an empty string if the user starts typing in the email field which will re enable the Next/LogIn Button
                  setErrorMessage("");
                }
              }}
              onBlur={formik.handleBlur}
              name="email"
              data-testid="email-input"
              onKeyDown={(event) => {
                if (
                  event.key === "Enter" &&
                  stage === "email" &&
                  !formik.errors.email
                ) {
                  setStage("password");
                }
              }}
              size="medium"
            />
          </>
        )}
        {stage === "password" && (
          <TextFieldOld
            label="Password"
            required
            error={
              !!errorMessage ||
              (formik.touched.password && Boolean(formik.errors.password))
            }
            helperText={
              errorMessage ||
              (formik.touched.password && formik.errors.password)
            }
            value={formik.values.password}
            onChange={(e) => {
              formik.handleChange(e);
              if (errorMessage) {
                // Set the error message to an empty string if the user starts typing in the password field which will re enable the Next/LogIn Button
                setErrorMessage("");
              }
            }}
            onBlur={formik.handleBlur}
            name="password"
            data-testid="password-input"
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                formik.handleSubmit();
              }
            }}
            size="medium"
            type="password"
            inputRef={passwordInputRef}
          />
        )}
        <NextContainer>
          <LogInAndBackButton>
            <Button
              label={stage === "email" ? "Next" : "Log in"}
              sx={{
                width: isMediumScreen ? "100%" : "160px",
                height: "44px",
              }}
              color="primary"
              variant="contained"
              disabled={
                !!errorMessage ||
                (stage === "email" &&
                  (!!formik.errors.email || !formik.values.email)) ||
                (stage === "password" &&
                  (!!formik.errors.password || !formik.values.password))
              }
              onClick={() => {
                handleNextClick();
                setErrorMessage("");
              }}
            />
          </LogInAndBackButton>

          {stage === "email" ? (
            <GetInTouchContainer>
              <Typography variant="body2" color="textSecondary">
                Don&apos;t have an account?
              </Typography>
              <Link
                label="Get in touch"
                visited={false}
                href="mailto:farmers@bx.tech"
                icon={<ChevronRightIcon />}
                sx={{
                  fontSize: "14px",
                  color: theme.palette.text.primary,
                  fontWeight: 500,
                }}
              />
            </GetInTouchContainer>
          ) : (
            <Button
              label="Forgotten password"
              sx={{
                height: "44px",
                whiteSpace: "nowrap",
                minWidth: "fit-content",
              }}
              color="primary"
              variant="text"
              onClick={forgottenPasswordOnClick}
            />
          )}
        </NextContainer>
      </FormContainer>
    </FormikProvider>
  );
};

export default LogIn;
