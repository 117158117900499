import { LinkTarget } from "@bxtech/bx-ui-lib/sections/navigation/Footer";

const navigationFooter = {
  main: [],
  footer_link_block_1_title: "",
  footer_link_block_1: [],
  footer_link_block_2_title: "",
  footer_link_block_2: [],
  footer_bottom_links: [
    { label: "Legal", url: { url: "https://bx.tech/terms/" } },
    {
      label: "Privacy Policy",
      url: { url: "https://bx.tech/privacy/" },
    },
    {
      label: "Contact Us",
      url: { url: "https://bx.tech/farmer-contact/" },
      target: "_blank" as LinkTarget,
    },
  ],
  socials: [
    {
      label: "Instagram",
      url: { url: "https://www.instagram.com/bx.tech/" },
    },
    {
      label: "LinkedIn",
      url: {
        url: "https://www.linkedin.com/company/bxtech/mycompany/verification/",
      },
    },
  ],
};

export default navigationFooter;
